<template>
    <div>
        <div class="min-h-screen" :class="pageTypeData.activeTab === 1 ? 'bg-[#F6F6F4]' : ''">
            <div class="sticky top-0 py-10 px-15 bg-white z-20">
                <div class="flex items-center justify-around">
                    <div
                        v-for="item in pageTypeData.tabs"
                        :key="item.id"
                        class="flex flex-col items-center"
                        :class="pageTypeData.activeTab === item.id ? 'font-bold text-[#CF8C57]' : 'text-[#9D9D9D]'"
                        @click="handleChangeTab(item)"
                    >
                        <div class="text-16 leading-22">{{ item.title }}</div>
                        <div class="mt-2 w-18 h-3 rounded-full" :class="pageTypeData.activeTab == item.id ? 'bg-[#CF8C57]' : ''"></div>
                    </div>
                </div>

                <div v-if="pageTypeData.activeTab === 1" class="mt-12 flex items-center justify-center">
                    <div class="text-16 leading-22">日期</div>
                    <div class="w-150 h-40 ml-10 px-16 rounded-full border-1 border-[#CECECE] flex items-center justify-between">
                        <i class="icon iconfont iconarrow-left-filling" :class="!changeDateStatus.subtract ? 'text-gray-300' : ''" @click="handleChangeDate('subtract')"></i>
                        <div class="leading-none" @click="handleSelectDate">{{ activeDate }}</div>
                        <i class="icon iconfont iconarrow-right-filling" :class="!changeDateStatus.add ? 'text-gray-300' : ''" @click="handleChangeDate('add')"></i>
                    </div>
                </div>
                <div v-else-if="pageTypeData.activeTab === 2" class="mt-15 flex">
                    <van-field
                        v-model="templateData.name"
                        maxlength="50"
                        placeholder="请输入药方关键字"
                        class="border-1 border-[#CECECE] px-15 py-10 text-14 leading-18 rounded-full"
                    />
                    <div class="__button _primary w-80 h-40 ml-10" @click="getTemplateListData">搜索</div>
                </div>
            </div>

            <template v-if="pageTypeData.activeTab === 1">
                <van-loading class="py-20" size="24px" vertical v-if="prescriptionData.loading">加载中...</van-loading>
                <div class="mt-20 text-center text-28 text-[#898989]" v-else-if="emptyArray(prescriptionData.list)">暂无数据</div>

                <div class="px-15">
                    <div class="mt-12 pb-30 rounded-10 overflow-hidden shadow px-20 bg-white bg-gradient-1" v-for="item in prescriptionData.list" :key="item.id">
                        <div class="mt-15 flex items-center">
                            <div class="text-16 leading-22 font-bold">{{ item.username }}</div>
                            <div class="ml-6 w-16 h-16 rounded-3 bg-[#FF9933] text-center leading-16 text-10 text-white" v-if="item.seetypename">{{ item.seetypename }}</div>

                            <div class="ml-auto text-14 leading-20 text-[#9D9D9D]">{{ item.seetime }}</div>
                        </div>

                        <div class="mt-8 text-[#9D9D9D] text-14 leading-20">
                            <span>{{ item.usersex }}</span>
                            <span class="ml-4">{{ item.age }}岁</span>
                            <span class="ml-4">{{ item.phone }}</span>
                        </div>

                        <div class="mt-10 h-1 bg-[#D8D8D8]"></div>

                        <div class="mt-10 flex justify-between text-14 leading-20">
                            <div class="w-84 mr-20 flex-shrink-0 text-[#555555]">中医诊断</div>
                            <div class="text-[#9D9D9D]">{{ item.diagnose }}</div>
                        </div>

                        <div class="mt-15" v-for="record in item.recordlist" :key="item.orderid">
                            <div class="flex justify-between text-14 leading-20">
                                <div class="w-84 mr-20 flex-shrink-0 text-[#555555]">药方</div>
                                <div class="text-[#9D9D9D]">{{ record.prescription }}</div>
                            </div>
                            <div class="mt-15 mx-auto w-120 __button _primary" @click="handleImportOrder(record)">确定引入药方</div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else-if="pageTypeData.activeTab === 2">
                <van-loading class="py-20" size="24px" vertical v-if="templateData.loading">加载中...</van-loading>
                <div class="mt-20 text-center text-28 text-[#898989]" v-else-if="templateData.listData.every((item) => emptyArray(item.list))">暂无数据</div>

                <div class="p-15" v-else>
                    <van-collapse v-model="templateData.activeCollapse" class="border-1 border-[#CECECE] rounded-8 overflow-hidden myCollapse">
                        <van-collapse-item
                            :name="collapse.key"
                            :title="collapse.title"
                            v-for="collapse in templateData.listData.filter((item) => !emptyArray(item.list))"
                            :key="collapse.key"
                            :border="false"
                            class="border-t-1 border-[#CECECE] first:border-none"
                        >
                            <div class="p-12">
                                <van-collapse v-model="collapse.activeCollapse" class="border-1 border-[#CECECE] rounded-8 overflow-hidden gray-title">
                                    <van-collapse-item
                                        :name="item.pid"
                                        :title="item.pname"
                                        v-for="item in collapse.list"
                                        :key="item.pid"
                                        :border="false"
                                        class="border-t-1 border-[#CECECE] first:border-none"
                                    >
                                        <div class="p-10 pb-15">
                                            <div class="flex text-14 leading-20 text-[#898989]">
                                                <div class="flex-shrink-0 w-56 h-20 text-[#898989] text-justify after:inline-block after:w-full">药方明细</div>
                                                <div class="flex-shrink-0 w-14 text-[#898989]">：</div>
                                                <div>{{ item.prescription }}</div>
                                            </div>

                                            <div class="flex text-14 leading-20 text-[#898989] mt-5">
                                                <div class="flex-shrink-0 w-56 h-20 text-[#898989] text-justify after:inline-block after:w-full">煎煮法</div>
                                                <div class="flex-shrink-0 w-14 text-[#898989]">：</div>

                                                <div>{{ item.decoctionmethod }}</div>
                                            </div>

                                            <div class="mx-auto __button _primary w-120 mt-15" @click="handleImportOrder(item)">确定引入药方</div>
                                        </div>
                                    </van-collapse-item>
                                </van-collapse>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
            </template>
        </div>

        <van-popup v-model="prescriptionData.datePopupShow" position="bottom">
            <van-picker
                title="选择日期"
                show-toolbar
                :columns="prescriptionData.dateList"
                :default-index="prescriptionData.activeDateIndex"
                @confirm="(date, index) => handleChangeDate('select', index)"
                @cancel="prescriptionData.datePopupShow = false"
            />
        </van-popup>
    </div>
</template>

<script>
import { emptyArray } from "../../utils";
import { wxqryprescriptiondate, wxqryhistoryprescription, wxqryprescriptiontemplate } from "@/api/medical";
import { mapGetters } from "vuex";

export default {
    name: "prescriptionHistory",
    components: {},
    data() {
        return {
            userIdData: {
                userid: null,
                customerid: null,
            },

            pageTypeData: {
                activeTab: 1,
                tabs: [
                    { id: 1, title: "历史处方" },
                    { id: 2, title: "处方模板", first: true },
                ],
            },

            // 处方数据
            prescriptionData: {
                dateList: [], // 日期列表
                activeDateIndex: 0, // 激活的日期下标

                datePopupShow: false,

                loading: false,
                list: [], // 处方列表
            },

            // 模板数据
            templateData: {
                name: "",

                loading: false,

                activeCollapse: [],
                // 列表数据
                listData: [
                    { key: "clinicTemplateList", title: "基础模板", list: [], activeCollapse: [] },
                    { key: "userTemplateList", title: "个人模板", list: [], activeCollapse: [] },
                ],
            },
        };
    },
    computed: {
        ...mapGetters(["username"]),
        changeDateStatus() {
            const { dateList, activeDateIndex } = this.prescriptionData;

            return {
                add: activeDateIndex !== 0,
                subtract: !emptyArray(dateList) && activeDateIndex !== dateList.length - 1,
            };
        },
        activeDate() {
            const { dateList, activeDateIndex } = this.prescriptionData;

            if (emptyArray(dateList)) {
                return null;
            }

            return dateList[activeDateIndex];
        },
    },
    methods: {
        emptyArray,

        /**
         * 切换tab
         */
        handleChangeTab(item) {
            this.pageTypeData.activeTab = item.id;

            if (item.id === 2 && item.first) {
                item.first = false;
                this.getTemplateListData();
            }
        },

        /**
         * 切换日期
         * @param type 类型 select：选择、add：下一日、subtract：上一日
         * @param index 选择的下标
         */
        handleChangeDate(type, index) {
            if (type === "select") {
                this.prescriptionData.activeDateIndex = index;
                this.prescriptionData.datePopupShow = false;
            }

            if (type === "add") {
                if (!this.changeDateStatus.add) {
                    return;
                }

                this.prescriptionData.activeDateIndex--;
            }

            if (type === "subtract") {
                if (!this.changeDateStatus.subtract) {
                    return;
                }
                this.prescriptionData.activeDateIndex++;
            }

            this.getHistoryListData();
        },

        /**
         * 选择日期
         */
        handleSelectDate() {
            if (emptyArray(this.prescriptionData.dateList)) {
                return;
            }

            this.prescriptionData.datePopupShow = true;
        },

        /**
         * 获取历史日期列表
         */
        async getHistoryDateList() {
            try {
                this.prescriptionData.loading = true;

                const { response_body: res } = await wxqryprescriptiondate(Object.assign({ username: this.username }, this.userIdData));

                this.prescriptionData.dateList = res.list || [];

                if (!emptyArray(this.prescriptionData.dateList)) {
                    this.getHistoryListData();
                } else {
                    this.prescriptionData.loading = false;
                }
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * 获取历史处方列表数据
         */
        async getHistoryListData() {
            try {
                this.prescriptionData.loading = true;
                this.prescriptionData.list = [];

                const requestData = Object.assign({ type: "0005", username: this.username, time: this.activeDate }, this.userIdData);
                const { response_body: res } = await wxqryhistoryprescription(requestData);

                this.prescriptionData.list = res.list || [];
            } catch (error) {
                console.error(error);
            } finally {
                this.prescriptionData.loading = false;
            }
        },

        /**
         * 获取处方模板列表数据
         */
        async getTemplateListData() {
            try {
                this.templateData.loading = true;
                this.templateData.activeCollapse = [];

                const { response_body: res } = await wxqryprescriptiontemplate({ username: this.username, name: this.templateData.name });

                this.templateData.listData.forEach((item) => {
                    this.templateData.activeCollapse.push(item.key);

                    item.list = res[item.key] || [];
                    item.activeCollapse = item.list.map((item) => item.pid);
                });
            } catch (error) {
                console.error(error);
            } finally {
                this.templateData.loading = false;
            }
        },

        /**
         * 导入药方
         */
        handleImportOrder(item) {
            this.$store.commit("setImportOrderData", {
                id: this.pageTypeData.activeTab === 1 ? item.orderid : item.pid,
                type: this.pageTypeData.activeTab,
            });

            this.$router.back();
        },
    },
    created() {
        this.$store.dispatch("hideOrShowNav", false);

        for (const key in this.userIdData) {
            this.userIdData[key] = this.$route.query[key];
        }

        this.getHistoryDateList();
    },
};
</script>
<style lang="scss" scoped>
.bg-gradient-1 {
    background-image: linear-gradient(to bottom, rgba(207, 140, 87, 0.2), transparent 75px);
}

.myCollapse {
    /deep/ {
        .van-collapse-item__title {
            height: 40px;
            background: #f4f4f4;
        }
        .van-collapse-item__content {
            padding: 0;
        }
    }
}
</style>
